// Constants
import { ADDONS } from '@/constants'
// Components
import CardContainerElement from '../CardContainerElement'
import ContactForm from '@/components/elements/contact/ContactForm'
import ShareSSNNLinks from '../ShareSSNNLinks'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Utils
import { getEnvironmentVariable, getFileFromUrl } from '@/utils'
import { get, isNil } from 'lodash'

export default {
  name: 'ShareLink',
  components: {
    CardContainerElement,
    ShareSSNNLinks
  },
  mixins: [addonsMixin, intercomMixin, uiMixin],
  props: {
    nameLink: {
      type: String,
      default: '',
      required: true
    },
    urlAppMenu: {
      type: String,
      default: '',
      required: true
    },
    urlQr: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    /**
     * Configuraciones (datos) de contacto del establecimiento
     *
     * @return {Object}
     */
    placeContactData() {
      return get(this.placeAddonsSetupByUser, ADDONS.contact, {})
    }
  },
  methods: {
    /**
     * Direcciona a la sección de impresión
     */
    handlePrintButton() {
      // Dirigimos a la impresión de cartas
      // o al mensaje de "necesitas actualizar"
      if (this.hasPrintMenuAddon) {
        this.routerPushTo({
          name: 'printMenuPreview'
        })
      } else {
        this.routerPushTo({
          name: 'addonsNeedUpgrade',
          params: {
            id: 'pro'
          }
        })
      }
    },
    /**
     * Cuando pulsamos sobrel botón de "Descargar código"
     */
    async handleDownloadButton() {
      const phone1 = get(this.placeContactData, 'phone1', null)
      const place = get(this.placeContactData, 'place', null)

      if (!isNil(phone1) && !isNil(place)) {
        // Descargamos QR
        this.downloadQrCode()
      } else {
        // Si no tiene numero o place le pedimos que lo complete
        this.modifyAppDialog({
          title: 'Completa tus datos de contacto',
          contentComponent: ContactForm,
          contentComponentProps: {
            headerText:
              '<b class="primary--text"> Antes de descargar el QR</b>, indica al menos un <u>teléfono</u> de\
              contacto y una <u>dirección</u> física de tu establecimiento para que tus clientes puedan\
              ubicarte (esta información se hará pública para tus clientes).',
            resultText: 'Los cambios se guardaron correctamente, ya puedes descargar tu QR.'
          },
          hideActionButtons: true,
          visible: true
        })
      }
    },
    /**
     * Descarga el código QR en el cliente
     */
    async downloadQrCode() {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(await getFileFromUrl(this.urlQr))

      a.href = url
      a.download = 'qr.png'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      // Intercom event
      if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
        this.intercomTrackEvent('downloadQr')
      }
    }
  }
}
