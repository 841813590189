// Mixins
import uiMixin from '@/mixins/uiMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Utils
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'ShareSSNNLinks',
  mixins: [intercomMixin, uiMixin],
  props: {
    url: {
      type: String,
      default: '',
      required: true
    }
  },
  methods: {
    /**
     * Set Url in clipboard
     */
    handleClickUrlToClipboard() {
      const tempInput = document.createElement('input')

      tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
      tempInput.value = this.url
      document.body.appendChild(tempInput)
      tempInput.select()

      try {
        document.execCommand('copy')
        // show info
        this.modifyAppAlert({
          text: '¡La URL se ha copiado con éxito!',
          visible: true
        })

        // Intercom event
        if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
          this.intercomTrackEvent('copyUrl')
        }
      } catch (err) {
        // show info
        this.modifyAppAlert({
          text: 'Ha ocurrido un error al copiar la URL',
          type: 'error',
          visible: true
        })
      } finally {
        document.body.removeChild(tempInput)
      }
    },
    /**
     * Share with WhatsApp
     */
    handleClickWhatsapp() {
      // Intercom event
      if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
        this.intercomTrackEvent('shareWhatsApp')
      }

      window.open(`https://api.whatsapp.com/send?text=${this.url}`, '_blank')
    },
    /**
     * Share with Facebook
     */
    handleClickFacebook() {
      // Intercom event
      if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
        this.intercomTrackEvent('shareFacebook')
      }

      window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.url}`, '_blank')
    },
    /**
     * Share with Twitter
     */
    handleClickTwitter() {
      // Intercom event
      if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
        this.intercomTrackEvent('shareTwitter')
      }

      window.open(`https://twitter.com/share?url=${this.url}`, '_blank')
    }
  }
}
